import { Link } from "gatsby"
import * as React from "react"
import tw from "twin.macro"
import Seo from "../components/SEO"

const NotFoundPage: React.FC = () => {
  return (
    <div css={[tw`h-screen flex flex-col items-center content-center justify-center text-gray-300`]}> 
    <p>404 - Page Not Found</p>
      <h1 css={[tw`mt-5 text-gray-700`]}>
        <Seo title="404 - Not Found" />
        Sorry, this page doesn't exist.
      </h1>
      <Link to={"/"}>
        <p css={[tw`hover:underline text-red-300 mt-5`]}>Go home.</p>
      </Link>
    </div>
  )
}

export default NotFoundPage
